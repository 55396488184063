import { render, staticRenderFns } from "./termsofuse.bak.vue?vue&type=template&id=14528928&scoped=true"
import script from "./termsofuse.bak.vue?vue&type=script&lang=js"
export * from "./termsofuse.bak.vue?vue&type=script&lang=js"
import style0 from "./termsofuse.bak.vue?vue&type=style&index=0&id=14528928&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "14528928",
  null
  
)

export default component.exports